<template>
    <div class="w-100 position-relative">
        <div class="card card-primary card-tabs" style="width: 100%;">
            <div class="card-header p-0 pt-1">
                <ul class="nav nav-tabs" id="custom-tabs-two-tab" role="tablist">
                    <li class="pt-2 px-3">
                        <h3 class="card-title">
                            <i class="fas fa-info-circle mr-1"></i>
                            <strong>Detail infomations</strong>
                        </h3>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link active"
                           data-toggle="pill"
                           href="#vmMachineInfomation"
                           role="tab"
                           aria-controls="custom-tabs-two-home"
                           aria-selected="true">
                            <i class="fas fa-info-circle"></i> Infomations
                        </a>
                    </li>
                    <li class="nav-item" v-if="checkAccess('GPUHUBBILLING_VIEW')">
                        <a class="nav-link"
                           data-toggle="pill"
                           href="#vmMachineBilling"
                           role="tab"
                           aria-controls="custom-tabs-two-home"
                           aria-selected="true">
                            <i class="fas fa-file-invoice"></i> Billing
                        </a>
                    </li>
                    <li class="nav-item" v-if="checkAccess('GPUMACHINEHISTORY_VIEW')">
                        <a class="nav-link"
                           data-toggle="pill"
                           href="#vmMachineHistory"
                           role="tab"
                           aria-controls="custom-tabs-two-home"
                           aria-selected="true">
                            <i class="fas fa-history"></i> Activity history
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link"
                           data-toggle="pill"
                           href="#remoteScreen"
                           role="tab"
                           aria-controls="custom-tabs-two-home"
                           aria-selected="true">
                            <i class="fas fa-play-circle"></i> Remote screen viewer
                        </a>
                    </li>
                    <li class="nav-item">
                        <a class="nav-link"
                           data-toggle="pill"
                           href="#bootingHistory"
                           role="tab"
                           aria-controls="custom-tabs-two-home"
                           aria-selected="true">
                            <i class="fas fa-rocket"></i> Booting history
                        </a>
                    </li>
                    <li class="nav-item" v-if="checkAccess('SOFTWARE_INSTALLED_RUNNING_VIEW')">
                        <a class="nav-link"
                           data-toggle="pill"
                           href="#vmMachineInstalledSoftware"
                           role="tab"
                           aria-controls="custom-tabs-two-home"
                           aria-selected="true">
                            <i class="fas fa-baseball-ball"></i> Software Installed
                        </a>
                    </li>
                    <li class="nav-item" v-if="checkAccess('PROCESS_RUNNING_VIEW')">
                        <a class="nav-link"
                           data-toggle="pill"
                           href="#vmMachineProccessList"
                           role="tab"
                           aria-controls="custom-tabs-two-home"
                           aria-selected="true">
                            <i class="fas fa-baseball-ball"></i> Proccess List
                        </a>
                    </li>
                    <!-- <li class="nav-item">
                        <a class="nav-link"
                           data-toggle="pill"
                           href="#vmMachineEventLogs"
                           role="tab"
                           aria-controls="custom-tabs-two-home"
                           aria-selected="true">
                            <i class="fas fa-align-right"></i> Event logs
                        </a>
                    </li> -->
                </ul>
            </div>
            <div class="card-body">
                <div class="tab-content" id="user-jobs">
                    <div class="tab-pane fade show active"
                         id="vmMachineInfomation"
                         role="tabpanel"
                         aria-labelledby="custom-tabs-two-home-tab">
                        <div class="mb-2 pl-1 d-flex align-items-center">
                            <img src="/img/remote-desktop-icon-13.png"
                                 style="width: 1rem; border-radius: 50%;"
                                 class="mr-1" />
                            <strong>Remote servers</strong>
                        </div>
                        <div class="d-flex flex-wrap">

                            <span v-if="detailMachineItem.vmMachineInfo.status === vmMachineStatus.Running && checkAccess('MACHINE_ACTION_DOWNLOAD_RDP')">
                                <a v-for="gateway in rdpGatewayServersGroup"
                                   :key="gateway.id"
                                   :href="`${VUE_APP_API_HOST}api/vmMachines/download-machine/${detailMachineItem.vmMachineId}/${gateway.id}`"
                                   target="_blank"
                                   class="btn btn-sm v-btn--rounded btn-default mr-3 mb-2 pt-1">
                                    <img :src="`/gateway/${gateway.id}.png`"
                                         style="width: 1.5rem; border-radius: 0;"
                                         class="mr-1" />
                                    {{gateway.name}} ({{gateway.groupName}})
                                </a>

                                <a :href="`${VUE_APP_API_HOST}api/vmMachines/download-machine/${detailMachineItem.vmMachineId}/VIETNAM`"
                                   target="_blank"
                                   class="btn btn-sm v-btn--rounded btn-default mr-3 mb-2 pt-1">
                                    <img src="/flag/VN.svg"
                                         style="width: 1.5rem; border-radius: 0;"
                                         class="mr-1" />
                                    Việt Nam
                                </a>
                            </span>

                            <button v-if="detailMachineItem.vmMachineInfo.status === vmMachineStatus.Running && checkAccess('MACHINE_ACTION_SHUTDOWN')"
                                    type="button"
                                    class="btn btn-sm btn-default v-btn--rounded mr-3 mb-2"
                                    @click="shutdownMachine(detailMachineItem.vmMachineId)">
                                <img src="/img/machine-down.svg" style="width: 1.5rem;" class="mr-1" /> Shutdown
                            </button>

                            <button v-if="[vmMachineStatus.Running, vmMachineStatus.Starting, vmMachineStatus.Restaring].includes(detailMachineItem.vmMachineInfo.status)
                                            && checkAccess('MACHINE_ACTION_RESET')"
                                    type="button"
                                    class="btn btn-sm btn-default v-btn--rounded mr-3 mb-2 pr-3"
                                    @click="resetMachine(detailMachineItem.vmMachineId)">
                                <img src="/img/reset.svg" style="width: 1.5rem;" class="mr-2" /> Reset
                            </button>

                        </div>
                        <div class="d-flex align-items-center ml-3 mr-3 mt-0">
                            <div class="mr-3">
                                <v-avatar size="4rem">
                                    <v-img id="app-avatar" src="/img/avatar5.png" />
                                </v-avatar>
                            </div>
                            <div class="flex-fill">
                                <h5 class="mt-2 mb-0 text-primary">
                                    <strong>{{detailMachineItem.userInfo.fullName}}</strong>
                                    <button type="button"
                                            class="btn btn-xs btn-default rounded p-0 pl-1 pr-1 ml-1"
                                            data-toggle="tooltip"
                                            title="Click copy to clipboard"
                                            @click="copyToClipboard('#infoInputCopy', `${detailMachineItem.userInfo.fullName}`)"
                                            style="font-size: 10px; position: relative; top: -2px;">
                                        <i class="fa fa-copy text-muted"></i>
                                    </button>
                                </h5>
                                <div>
                                    <span class="text-muted">
                                        {{processEmailUser(detailMachineItem.staffSupport, detailMachineItem.userInfo.email)}}
                                    </span>
                                    <button type="button" v-if="isVisibleEmail(detailMachineItem.staffSupport)"
                                            class="btn btn-xs btn-default rounded p-0 pl-1 pr-1 ml-1"
                                            data-toggle="tooltip"
                                            title="Click copy to clipboard"
                                            @click="copyToClipboard('#infoInputCopy', `${detailMachineItem.userInfo.email}`)"
                                            style="font-size: 10px; position: relative; top: -2px;">
                                        <i class="fa fa-copy text-muted"></i>
                                    </button>
                                </div>
                                <div>
                                    <span class="text-muted">{{processUserPhone(detailMachineItem.staffSupport, detailMachineItem.userInfo.phone)}}</span>
                                    <button type="button" v-if="isVisiblePhone(detailMachineItem.staffSupport)"
                                            class="btn btn-xs btn-default rounded p-0 pl-1 pr-1 ml-1"
                                            data-toggle="tooltip"
                                            title="Click copy to clipboard"
                                            @click="copyToClipboard('#infoInputCopy', `${detailMachineItem.userInfo.phone}`)"
                                            style="font-size: 10px; position: relative; top: -2px;">
                                        <i class="fa fa-copy text-muted"></i>
                                    </button>
                                </div>
                            </div>
                            <div>
                                <div class="custom-callout left" style="width: 25rem;">
                                    <strong>Ami Bụng Bự</strong>
                                    <br />
                                    <div style="font-size: 90%; line-height: 18px;">
                                        <span v-if="totalRechargeInfo.countTrans === 0">
                                            Khách hàng
                                            <strong class="text-warning">{{detailMachineItem.userInfo.fullName}}</strong> chưa từng nạp tiền lần nào !
                                        </span>
                                        <span v-else>
                                            Khách hàng
                                            <strong class="text-warning">{{detailMachineItem.userInfo.fullName}}</strong> đã nạp tiền
                                            <strong class="text-warning">{{totalRechargeInfo.countTrans}} lần</strong>, tổng đã nạp là
                                            <strong class="text-warning">{{totalRechargeInfo.totalAmount}}$</strong>, không tính TESTING !
                                        </span>
                                    </div>
                                </div>
                                <img v-if="totalRechargeInfo.countTrans === 0"
                                     src="/img/no-data-3.jpg"
                                     style="height: 5.5rem;" />
                                <img v-else src="/img/no-data-4.jpg" style="height: 5.5rem;" />
                            </div>
                        </div>
                        <VuePerfectScrollbar style="height: calc(100dvh - 28rem);"
                                             :settings="{ suppressScrollX : true }">
                            <div class="w-100">
                                <div style="margin-left: 1rem; margin-right: 1rem;">
                                    <div class="row">
                                        <div class="col-xs-12 col-md-6">
                                            <div class="mb-1">
                                                <strong>Machine infomations</strong>
                                            </div>
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 1%;" class="text-nowrap">Infomation</th>
                                                        <th>Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr v-if="detailMachineItem.userInfo.partnerInfo">
                                                        <td>PARTNER</td>
                                                        <td>
                                                            <div>
                                                                <span class="label bg-gradient-danger pb-1">
                                                                    <span>{{detailMachineItem.userInfo.partnerInfo.PartnerName}}</span>
                                                                </span>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-nowrap">Image Name</td>
                                                        <td>
                                                            <strong>{{detailMachineItem.vmMachineInfo.name}}</strong>
                                                        </td>
                                                    </tr>
                                                    <tr v-if="detailMachineItem.vmMachineInfo.vhdxLength">
                                                        <td class="text-nowrap">Image Size</td>
                                                        <td>
                                                            <strong>{{detailMachineItem.vmMachineInfo.vhdxLength | formatSize}}</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-nowrap">Package Name</td>
                                                        <td>
                                                            <strong>{{detailMachineItem.vmMachineInfo.packageName}}</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-nowrap">Computer Name</td>
                                                        <td>
                                                            <strong>{{detailMachineItem.vmMachineInfo.computerName}}</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-nowrap">Description</td>
                                                        <td>
                                                            <strong>{{detailMachineItem.vmMachineInfo.description}}</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-nowrap">MAC Address</td>
                                                        <td>
                                                            <strong>{{detailMachineItem.vmMachineInfo.macAddress}}</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-nowrap">IP Address</td>
                                                        <td>
                                                            <strong>{{detailMachineItem.vmMachineInfo.ipAddress}}</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-nowrap">Remote Ready</td>
                                                        <td>
                                                            <strong>{{detailMachineItem.vmMachineInfo.remoteReady}}</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-nowrap">Status</td>
                                                        <td>
                                                            <strong>{{detailMachineItem.vmMachineInfo.statusText}}</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-nowrap">Created At</td>
                                                        <td>
                                                            <strong>{{detailMachineItem.createdAt | pretyDateUtc}}</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-nowrap">Start time</td>
                                                        <td>
                                                            <strong>{{detailMachineItem.vmMachineInfo.startTime | pretyDateUtc}}</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-nowrap">End time</td>
                                                        <td>
                                                            <strong>{{detailMachineItem.vmMachineInfo.endTime | pretyDateUtc}}</strong>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>

                                        <div class="col-xs-12 col-md-6">
                                            <div class="mb-1">
                                                <strong>Rental Service package infomations</strong>
                                            </div>
                                            <table class="table table-bordered">
                                                <thead>
                                                    <tr>
                                                        <th style="width: 1%;" class="text-nowrap">Infomation</th>
                                                        <th>Details</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr>
                                                        <td class="text-nowrap">Service Pack ID</td>
                                                        <td>
                                                            <strong>{{detailMachineItem.vmMachineInfo.rentalServicePack.id}}</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-nowrap">Service Pack name</td>
                                                        <td>
                                                            <strong>{{detailMachineItem.vmMachineInfo.rentalServicePack.name}}</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-nowrap">CPU infomation</td>
                                                        <td>
                                                            <strong>
                                                                {{detailMachineItem.vmMachineInfo.rentalServicePack.detailCpu}}&nbsp;
                                                                {{detailMachineItem.vmMachineInfo.rentalServicePack.cpuInformation}}
                                                            </strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-nowrap">Graphic infomation</td>
                                                        <td>
                                                            <strong>{{detailMachineItem.vmMachineInfo.rentalServicePack.detailGraphic}}</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-nowrap">Number of Graphics card</td>
                                                        <td>
                                                            <strong>{{detailMachineItem.vmMachineInfo.rentalServicePack.numberCard}}</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-nowrap">Memory infomation</td>
                                                        <td>
                                                            <strong>{{detailMachineItem.vmMachineInfo.rentalServicePack.detailMemory}}</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-nowrap">Storage infomation</td>
                                                        <td>
                                                            <strong>{{detailMachineItem.vmMachineInfo.rentalServicePack.detailStorage}}</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-nowrap">Support infomation</td>
                                                        <td>
                                                            <strong>{{detailMachineItem.vmMachineInfo.rentalServicePack.detailSupport}}</strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-nowrap">Pricing</td>
                                                        <td>
                                                            <strong>
                                                                {{detailMachineItem.vmMachineInfo.rentalServicePack.pricing}}{{$pointNameShort}}
                                                                {{detailMachineItem.vmMachineInfo.rentalServicePack.pricingUnit}}
                                                            </strong>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td class="text-nowrap">Micro Architecture</td>
                                                        <td>
                                                            <strong>{{detailMachineItem.vmMachineInfo.rentalServicePack.microarchitecture}}</strong>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                                <div>
                                    <hr />
                                    <comments v-if="detailMachineItem !== null"
                                              :detailItem="detailMachineItem"
                                              module="vmMachine"
                                              :settings="{ itemIdCol : 'id' }"
                                              @newestCommentUpdated="newestCommentUpdated" />
                                </div>
                            </div>
                        </VuePerfectScrollbar>
                    </div>
                    <div v-if="checkAccess('GPUHUBBILLING_VIEW')"
                         class="tab-pane fade"
                         id="vmMachineBilling"
                         role="tabpanel"
                         aria-labelledby="custom-tabs-two-home-tab">
                        <listBillingOfMachine :cardHeight="'height: calc(100dvh - 18.5rem);'"
                                              :minInfo="true"
                                              :ownedTarget="detailMachineItem.userId"
                                              :machineTarget="detailMachineItem.vmMachineId" />
                    </div>
                    <div v-if="checkAccess('GPUMACHINEHISTORY_VIEW')"
                         class="tab-pane fade"
                         id="vmMachineHistory"
                         role="tabpanel"
                         aria-labelledby="custom-tabs-two-home-tab">
                        <userHistoryList :cardHeight="'height: calc(100dvh - 18rem);'"
                                         :minInfo="true"
                                         :username="detailMachineItem.userInfo.username"
                                         :machineId="detailMachineItem.vmMachineId" />
                        <!--<vmMachineHistory :cardHeight="'height: calc(100dvh - 18.5rem);'"
                                          :minInfo="true"
                                          :machineTarget="detailMachineItem.vmMachineId" />-->
                    </div>
                    <div class="tab-pane fade"
                         id="remoteScreen"
                         role="tabpanel"
                         aria-labelledby="custom-tabs-two-home-tab">
                        <!--<listBillingOfMachine :cardHeight="'height: calc(100dvh - 18.5rem);'" :minInfo="true"
                        :machineTarget="detailMachineItem.vmMachineId"/>-->
                        <screenViewMachine style="height: calc(100dvh - 17.3rem);"
                                           :computerName="detailMachineItem.vmMachineInfo.computerName" />
                    </div>
                    <div class="tab-pane fade"
                         id="bootingHistory"
                         role="tabpanel"
                         aria-labelledby="custom-tabs-two-home-tab">
                        <bootingChart :cardHeight="'height: calc(100dvh - 18.5rem);'"
                                      :machineItem="detailMachineItem" />
                    </div>
                    <div class="tab-pane fade"
                         id="vmMachineInstalledSoftware"
                         role="tabpanel"
                         aria-labelledby="custom-tabs-two-home-tab">
                        <vmMachineInstalledSoftware :cardHeight="'height: calc(100dvh - 18.5rem);'"
                                                    :detailMachineItem="detailMachineItem" />
                    </div>
                    <div class="tab-pane fade"
                         id="vmMachineProccessList"
                         role="tabpanel"
                         aria-labelledby="custom-tabs-two-home-tab">
                        <vmMachineProccessList :cardHeight="'height: calc(100dvh - 18.5rem);'"
                                               :detailMachineItem="detailMachineItem" />
                    </div>
                    <!-- <div class="tab-pane fade"
                         id="vmMachineEventLogs"
                         role="tabpanel"
                         aria-labelledby="custom-tabs-two-home-tab">
                        <detailMachineEventlog style="height: calc(100dvh - 18.5rem);"
                                               :machineId="detailMachineItem.vmMachineId" />
                    </div> -->
                </div>
            </div>
            <!-- /.card -->
        </div>
    </div>
</template>

<script>
    import commonAct from "@/commonActionHandle.js";
    import lazyLoadComponent from "@/scripts/lazyLoadComponent";
    import SkeletonBox from "@/components/SkeletonBox";
    import { vmMachineStatus } from "@/constant/vmMachineStatusConst";
    import vmMachineApi from "@/api/gpuHub/vmMachines";
    import { API_CALL_STATUS } from "@/constant/config";
    import userApi from "@/api/common/users";
    import baseComponent from "@/scripts/baseComponent";
    export default {
        extends: baseComponent,
        components: {
            listBillingOfMachine: lazyLoadComponent({
                componentFactory: () => import("@/views/gpuHub/billing/billing"),
                loading: SkeletonBox,
            }),
            comments: lazyLoadComponent({
                componentFactory: () => import("@/components/Comments"),
                loading: SkeletonBox,
            }),
            screenViewMachine: lazyLoadComponent({
                componentFactory: () =>
                    import("@/views/gpuHub/vmMachines/screenViewMachine"),
                loading: SkeletonBox,
            }),
            // vmMachineHistory: lazyLoadComponent({
            //    componentFactory: () =>
            //        import("@/views/gpuHub/vmMachines/vmMachineHistory"),
            //    loading: SkeletonBox,
            // }),
            bootingChart: lazyLoadComponent({
                componentFactory: () => import("@/views/gpuHub/vmMachines/bootingChart"),
                loading: SkeletonBox,
            }),
            vmMachineInstalledSoftware: lazyLoadComponent({
                componentFactory: () => import("@/views/gpuHub/vmMachines/vmMachineInstalledSoftware"),
                loading: SkeletonBox,
            }),
            vmMachineProccessList: lazyLoadComponent({
                componentFactory: () => import("@/views/gpuHub/vmMachines/vmMachineProccessList"),
                loading: SkeletonBox,
            }),
            userHistoryList: lazyLoadComponent({
                componentFactory: () => import("@/views/users/UsersHistory"),
                loading: SkeletonBox,
            }),
            // detailMachineEventlog: lazyLoadComponent({
            //     componentFactory: () => import("@/views/gpuHub/vmMachines/detailMachineEventlog"),
            //     loading: SkeletonBox,
            // }),
        },
        props: {
            detailMachineItem: {
                type: Object,
                default: null,
            },
        },
        watch: {
            detailMachineItem(newVal) {
                if (this.detailMachineItem !== null) {
                    this.getTotalRechargeInfo();
                    this.rdpGatewayAdminGetAllGateway();
                    this.getSoftwareInstalledByMachineId();
                }
            },
        },
        data() {
            return {
                VUE_APP_API_HOST: process.env.VUE_APP_API_HOST,
                vmMachineStatus: vmMachineStatus,
                rdpGatewayServers: [],
                totalRechargeInfo: {
                    countTrans: 0,
                    totalAmount: 0,
                },
            };
        },
        computed: {
            rdpGatewayServersGroup() {
                try {
                    if (this.rdpGatewayServers) {
                        let sorted = this.rdpGatewayServers.slice().sort((a, b) => {
                            if (a.groupName < b.groupName) { return -1; }
                            if (a.groupName > b.groupName) { return 1; }
                            return 0;
                        });
                        return sorted;
                    }
                    else return [];
                } catch (error) {
                    return [];
                }
            }
        },
        mounted() {
            this.getTotalRechargeInfo();
            this.getSoftwareInstalledByMachineId();
            this.rdpGatewayAdminGetAllGateway();
        },
        methods: {
            rdpGatewayAdminGetAllGateway() {
                vmMachineApi.rdpGatewayAdminGetAllGateway().then(res => {
                    if (res.data && res.data.result === 0 && res.data.data !== null) {
                        this.rdpGatewayServers = res.data.data;
                    }
                    else {
                        this.rdpGatewayServers = [];
                        if (res.data && res.data.message !== null && res.data.message !== '') {
                            console.error(res.data.message || this.$lang.common.error);
                        }
                    }
                }).catch(error => {
                    console.error(error);
                    this.rdpGatewayServers = [];
                });
            },
            createBilling(machineId) {
                commonAct.showConfirm("Re-create billing for this machine ?", () => {
                    this.showLoading();
                    vmMachineApi
                        .createBilling(machineId)
                        .then((response) => {
                            if (response.data && response.data.result === 0) {
                                this.hideLoading();
                                this.showSuccessToast("Re-create billing has been success");
                            } else {
                                this.hideLoading();
                                if (
                                    response.data &&
                                    response.data.message !== null &&
                                    response.data.message !== ""
                                ) {
                                    commonAct.showError(
                                        response.data.message || this.$lang.common.error
                                    );
                                }
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            this.hideLoading();
                            commonAct.showError(error);
                        });
                });
            },

            shutdownMachine(machineId) {
                commonAct.showConfirm("Shutdown this machine ?", () => {
                    this.showLoading();
                    let request = {
                        machineId: machineId,
                        deviceInformation: JSON.stringify(this.getInformationClient()),
                    };
                    vmMachineApi
                        .shutdownMachine(request)
                        .then((response) => {
                            if (response.data && response.data.result === 0) {
                                this.hideLoading();
                                this.showSuccessToast(
                                    "Request shutdown machine has been success"
                                );
                            } else {
                                this.hideLoading();
                                if (
                                    response.data &&
                                    response.data.message !== null &&
                                    response.data.message !== ""
                                ) {
                                    commonAct.showError(
                                        response.data.message || this.$lang.common.error
                                    );
                                }
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            this.hideLoading();
                            commonAct.showError(error);
                        });
                });
            },
            resetMachine(machineId) {
                commonAct.showConfirm("Reset this machine ?", () => {
                    this.showLoading();
                    let request = {
                        machineId: machineId,
                        deviceInformation: JSON.stringify(this.getInformationClient()),
                    };
                    vmMachineApi
                        .resetMachine(request)
                        .then((response) => {
                            if (response.data && response.data.result === 0) {
                                this.hideLoading();
                                this.showSuccessToast("Request reset machine has been success");
                            } else {
                                this.hideLoading();
                                if (
                                    response.data &&
                                    response.data.message !== null &&
                                    response.data.message !== ""
                                ) {
                                    commonAct.showError(
                                        response.data.message || this.$lang.common.error
                                    );
                                }
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            this.hideLoading();
                            commonAct.showError(error);
                        });
                });
            },
            updateBootReady(machineId) {
                commonAct.showConfirm("Set this machine ready to boot ?", () => {
                    this.showLoading();
                    vmMachineApi
                        .updateBootReady(machineId)
                        .then((response) => {
                            if (response.data && response.data.result === 0) {
                                this.hideLoading();
                                this.showSuccessToast(
                                    "Request boot machine ready has been success"
                                );
                            } else {
                                this.hideLoading();
                                if (
                                    response.data &&
                                    response.data.message !== null &&
                                    response.data.message !== ""
                                ) {
                                    commonAct.showError(
                                        response.data.message || this.$lang.common.error
                                    );
                                }
                            }
                        })
                        .catch((error) => {
                            console.error(error);
                            this.hideLoading();
                            commonAct.showError(error);
                        });
                });
            },
            newestCommentUpdated(noteContent) { },
            getTotalRechargeInfo() {
                userApi
                    .getTotalRechargeInfo(this.detailMachineItem.userId)
                    .then((response) => {
                        if (
                            response.data &&
                            response.data.result === 0 &&
                            response.data.data !== null
                        ) {
                            this.$set(this, "totalRechargeInfo", response.data.data);
                        } else {
                            this.$set(this, "totalRechargeInfo", {
                                countTrans: 0,
                                totalAmount: 0,
                            });
                        }
                    })
                    .catch((error) => {
                        this.$set(this, "totalRechargeInfo", {
                            countTrans: 0,
                            totalAmount: 0,
                        });
                        console.error(error);
                    });
            },
            getSoftwareInstalledByMachineId() {
                vmMachineApi
                    .getSoftwareInstalledByMachineId(this.detailMachineItem.vmMachineId)
                    .then((response) => {
                        if (
                            response.data &&
                            response.data.result === 0 &&
                            response.data.data !== null
                        ) {
                            //this.$set(this, "totalRechargeInfo", response.data.data);
                        }
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            }
        },
    };
</script>